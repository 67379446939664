<template>
    <div class="top-level">
        <div class="text-h3 soon-to-come">Soon to come...</div>
    </div>
</template>

<script>
export default ({
    name: 'Photos',
    data() {
        return {
            topics: [
                {
                    name: 'topic 1',
                    pictures: [
                        '',
                    ],
                },
            ]
        };
    },
})
</script>

<style scoped lang="less">
.top-level {}
.soon-to-come {
    text-align: center;
    margin-top: 3em;
    font-style: italic;
}
</style>
